<template>
  <div class="home">

    <dashboard/>
  </div>
</template>

<script>
import dashboard from "@/components/InvestorsDashboard.vue";

export default {
  name: "Home",
  components: {
    dashboard
  }
};
</script>
